module.exports = {
  comm: {
    home: 'Главная',
    pricing: 'Цены',
    blog: 'Клонирование голоса и API',
    language: 'язык:',
    select_language: 'выбрать язык',
    txt2voice: 'текст в речь',
    voice2txt: 'речь в текст',
    voiceclone: 'клонирование голоса',
    video2txt: 'видео в текст',
    footer_help: 'Нужна помощь? Напишите нам:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Войти',
    login_desc: 'Войдите, чтобы получить доступ к вашему аккаунту Генератора Голосов TikTok',
    logout: 'Выйти',
  },
  txt2voice: {
    title: 'Генератор голосов TikTok: Создавайте забавные AI-голоса TikTok',
    description: 'Создавайте и загружайте забавные AI-голоса TikTok бесплатно, такие как голос jessie, голос C3PO, голос ghostface...',
    keywords: 'Генератор голосов TikTok, AI-голос TikTok, Генератор голосов Tik Tok',
    main_title_p01: 'Генератор голосов TikTok',
    main_title_p02: 'Создавайте забавные AI-голоса TikTok: голос jessie, голос C3PO, голос ghostface',
    main_textarea_holder: 'Введите или вставьте текст здесь',
    main_genvoice: 'создать',
    main_generating: 'создание',
    main_input_empty: 'пожалуйста, введите или вставьте текст',
    daily_usage_limit_msg: '1) Достигнут максимальный лимит использования на день. Пожалуйста, вернитесь завтра. 2) Если вам срочно нужно больше использования, пожалуйста, напишите нам на email.',
    text_max_prompt1: 'Пожалуйста, ограничьте количество слов до',
    text_max_prompt2: 'слов или меньше!',
    popup_nologin_title: 'Обновите ваш план подписки',
    popup_nologin_desc: 'Разблокируйте больше времени для генерации голоса, обновившись до премиум-подписки.',
    popup_nologin_btntext: 'Изучить планы подписки',
    popup_nosub_title: 'Обновите ваш план подписки',
    popup_nosub_desc: 'Разблокируйте больше времени для генерации голоса, обновившись до премиум-подписки.',
    popup_nosub_btntext: 'Изучить планы подписки',
    popup_sublimit_title: 'Подписка исчерпана, пожалуйста, обновите',
    popup_sublimit_desc: 'Улучшите свой опыт с нашими премиум-функциями и неограниченным доступом.',
    popup_sublimit_btntext: 'Перейти к покупке',
  },
  pricing: {
    new_features_alert: "📣 Мы запустили услуги 🎙️ Клонирования голоса и 🤖 API для платных участников! (После оплаты напишите на tiktokaivoicetool@gmail.com для помощи с этими функциями). Кроме того, не стесняйтесь писать нам по любым запросам на настройку😃",
    pricing_title: 'От индивидуальных создателей до крупнейших предприятий, у нас есть план для вас.',
    pricing_desc: 'Более 100 естественных, человеческих голосов. Более 15 языков. и почти самые низкие цены для всех',
    sub_free_name: 'Бесплатно',
    sub_free_desc: 'Для тех, кто хочет попробовать самое передовое AI-аудио',
    sub_free_content: [
      'Лимит 1000 символов в месяц',
      '5 генераций в день',
    ],
    sub_starter_name: 'Стартовый',
    sub_starter_desc: 'Для создателей, производящих премиум-контент для глобальной аудитории',
    sub_starter_content: [
      '1 клон голоса',
      'Лимит 300 000 символов в месяц (примерно 7 часов аудио)',
      'Неограниченное количество загрузок',
      'Поддержка по электронной почте в течение 72 часов',
      'Приоритетный доступ к новым голосам и функциям',
    ],
    sub_pro_name: 'Про',
    sub_pro_desc: 'Для создателей, расширяющих свое производство контента',
    sub_pro_content: [
      'Доступ к API',
      '3 клона голоса',
      'Лимит 1 000 000 символов в месяц (примерно 24 часа аудио)',
      'Неограниченное количество загрузок',
      'Поддержка по электронной почте в течение 48 часов',
      'Приоритетный доступ к новым голосам и функциям',
    ],
    period_year: 'Ежегодно',
    period_month: 'Ежемесячно',
    period_month_short: 'мес',
    billed_year: 'Оплата ежегодно',
    most_popular: 'Самый популярный',
    discount_quantity: 'Сэкономьте $48',
    buy_btntext: 'Начать',
    pay_succ: 'Оплата успешна!',
    pay_succ_desc: 'Спасибо за вашу покупку. Ваша транзакция была успешно обработана.',
    pay_succ_btntext: 'Начать использовать TikTok Voice',
    pay_fail: 'Оплата не удалась!',
    pay_fail_desc: "Извините, мы не смогли обработать вашу транзакцию. Пожалуйста, попробуйте еще раз или свяжитесь с нашей службой поддержки (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Вернуться на главную страницу',
  },
  setting: {
    setting: 'Настройки',
    setting_title: 'Управляйте своим аккаунтом, использованием и подпиской здесь.',
    basic_info: 'Основная информация',
    user_name: 'Имя',
    user_email: 'Email',
    user_account: 'Аккаунт',
    user_subscript: 'Текущий план',
    user_usage: 'Использование',
    manage_subscript: 'Управление подпиской',
    use_limit_promote: "Вы использовали 5000 из вашего месячного лимита в 10000 символов.",
    not_logged_in: 'Вы не вошли в систему',
    go_to_login: 'Перейти к входу >>',
  },
  login: {
    title: "Генератор Голосов TikTok",
    subtitle: "Войдите, чтобы получить доступ к вашему аккаунту Генератора Голосов TikTok",
    alreadyLoggedIn: "Вы уже вошли в систему",
    goToHome: "Перейти на главную страницу"
  },
  faq: {
    quest1: 'Генератор голосов TikTok - лучший инструмент для создания AI-голосов TikTok',
    answer1_1: 'Это инструмент генератора голосов AI tiktok, разработанный с использованием новейшей технологии tts tiktok. Он может генерировать голоса tiktok, такие как голос jessie (женский голос), голос siri, голос ghostface, голос C3PO (роботизированный голос), глубокий голос (рассказчик), голос skye, теплый голос, голос bestie, голос героя (голос chiris), эмпатический голос, серьезный голос, голос joey, голос stitch, голос штурмовика (Звездные войны), голос Rocket (Стражи Галактики).',
    answer1_2: 'Скоро будут поддерживаться голоса AI tiktok, такие как: голос derek, голос trickster, голос austin butler, голос репортера, голос adam, голос собаки, голос miley cyrus, голос alexa, голос инопланетянина, голос животного, голос младенца, голос компьютера, голос бурундука, голос echo, голос npr.',
    answer1_3: 'Кроме того, инструмент генератора голосов tiktok поддерживает несколько других языков, включая китайский, японский, корейский, вьетнамский, тайский, хинди, персидский, русский, немецкий, французский, румынский, чешский, испанский, португальский, бенгальский, итальянский, арабский, урду, традиционный китайский и малайский.',
    answer1_4: 'Если вам срочно нужен определенный голос, пожалуйста, напишите мне на почту.',

quest2: 'Какие преимущества у генератора голосов TikTok?',
    answer2_1: '- Инструмент генератора голосов TikTok может создавать различные типы голосов, которые часто используются в видео TikTok.',
    answer2_2: '- Это новейший генератор голосов AI, который может создавать голоса tiktok tts, похожие на человеческие.',
    answer2_3: '- Он более удобен для редактирования видео на ПК.',
    answer2_4: '- Вы можете использовать некоторые голоса, которые в настоящее время нельзя найти в приложении tiktok tts.',

quest3: 'Как использовать генератор голосов TikTok?',
    answer3_1: 'Генератор голосов TikTok очень прост в использовании:',
    answer3_2: '- Выберите язык и акцент голоса.',
    answer3_3: '- Введите текст, который нужно преобразовать в речь, в текстовое поле.',
    answer3_4: '- Нажмите кнопку создать и подождите несколько секунд.',
    answer3_5: '- Воспроизведите или загрузите AI-голос tiktok.',

quest4: 'Какие самые знаменитые и забавные голоса tiktok?',
    answer4_1: 'Самый знаменитый мужской голос tiktok - это глубокий голос, официально называемый рассказчиком.',
    answer4_2: 'Для самого забавного голоса tiktok я лично рекомендую голос ghostface и голос C3PO. Эти голоса часто используются для озвучки забавных видео TikTok, так как они легко узнаваемы и могут быстро увлечь зрителей.',

quest5: 'Какие самые раздражающие голоса TikTok?',
    answer5_1: 'Я считаю, что на самом деле нет "раздражающих" голосов tiktok.',
    answer5_2: 'Все зависит от контекста и содержания, в котором используется голос, что может вызвать дискомфорт у некоторых людей. Например, некоторые люди могут считать голос jessie раздражающим, потому что он слишком часто появлялся на TikTok в определенный период. Однако, благодаря своему тембру и качеству, это на самом деле отличный эффект голоса tiktok. Кроме того, актриса озвучивания tiktok, стоящая за этим голосом, очень профессиональна, и она сама публикует свои видео на TikTok.',
    answer5_3: 'Поэтому я предлагаю всем оставить свои опасения и предвзятости, несколько раз прослушать голоса из этого инструмента и терпеливо найти тот эффект голоса tiktok, который подходит именно вам.',

quest6: 'Как использовать генератор голосов TikTok для создания голоса jessie?',
    answer6_1: '- В первом выпадающем меню инструмента генератора голосов TikTok выберите English (US), затем в другом выпадающем меню выберите голос Jessie (женский голос).',
    answer6_2: '- Введите текст в текстовое поле и нажмите кнопку создать.',
    answer6_3: '- Подождите несколько секунд или до десяти секунд, и вы услышите AI-голос. В панели управления найдите кнопку со стрелкой вниз и нажмите на нее для загрузки.',

quest7: 'Как использовать генератор голосов TikTok для создания голоса C3PO?',
    answer7_1: '- В первом выпадающем меню инструмента генератора голосов TikTok выберите English (US), затем в другом выпадающем меню выберите голос C3PO (роботизированный голос).',
    answer7_2: '- Введите текст в текстовое поле и нажмите кнопку создать.',
    answer7_3: '- Подождите несколько секунд или до десяти секунд, и вы услышите AI-голос. В панели управления найдите кнопку со стрелкой вниз и нажмите на нее для загрузки.',

quest8: 'Как использовать генератор голосов TikTok для создания голоса ghostface?',
    answer8_1: '- В первом выпадающем меню инструмента генератора голосов TikTok выберите English (US), затем в другом выпадающем меню выберите голос ghostface.',
    answer8_2: '- Введите текст в текстовое поле и нажмите кнопку создать.',
    answer8_3: '- Подождите несколько секунд или до десяти секунд, и вы услышите AI-голос. В панели управления найдите кнопку со стрелкой вниз и нажмите на нее для загрузки.',

quest9: 'Как использовать генератор голосов TikTok для создания голоса siri?',
    answer9_1: '- В первом выпадающем меню инструмента генератора голосов TikTok выберите English (US), затем в другом выпадающем меню выберите голос siri.',
    answer9_2: '- Введите текст в текстовое поле и нажмите кнопку создать.',
    answer9_3: '- Подождите несколько секунд или до десяти секунд, и вы услышите AI-голос. В панели управления найдите кнопку со стрелкой вниз и нажмите на нее для загрузки.',
    
quest10: 'Как добавить эффект tiktok голоса на видео TikTok?',
    answer10_1: 'Если вы хотите узнать, как использовать официальные голоса tiktok на TikTok, я скоро напишу специальный блог-пост на эту тему.',
    answer10_2: 'Здесь я объясню, как опубликовать ваш голос в TikTok после его создания и загрузки с генератора голосов TikTok.',
    answer10_3: '1. Если вы создали голос tiktok на своем ПК и хотите загрузить его в TikTok или любое другое приложение для редактирования видео на своем телефоне, вам нужно передать файл голоса на свой телефон. Для iPhone вы можете использовать airDrop для передачи. Для телефонов Android я не очень знаком с этим процессом, но вы наверняка найдете способы и инструменты для этого.',
    answer10_4: '2. Как только файл голоса будет передан на ваш телефон, вы можете открыть TikTok:',
    answer10_5: '- Нажмите на кнопку "+" внизу интерфейса, выберите видео на своем телефоне.',
    answer10_6: '- После загрузки видео найдите квадратный значок справа на экране, чтобы войти в страницу редактирования.',
    answer10_7: '- Внизу страницы найдите кнопку "добавить голос" и нажмите, чтобы выбрать только что переданный файл голоса.',
    answer10_8: '- В этом интерфейсе вы можете внести некоторые изменения в видео, а затем нажать кнопку в правом верхнем углу, чтобы опубликовать его.',
    
quest11: 'TikTok Voice бесплатный?',
    answer11: 'Да, это бесплатный инструмент генератора AI голосов tiktok из текста в речь.',
    
quest12: 'Где я могу создать популярный голос TikTok для своего видео TikTok?',
    answer12: 'Вы можете посетить https://tiktokvoice.net/, чтобы добавить AI голос tiktok в свое видео.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}